import React from "react";
import styled, { css } from "styled-components";

import CtaButton, { CtaButtonInterface } from "../cta-button/cta-button";

interface MenuItem {
  title: string;
  link?: string;
}

interface logoImage {
  url: string;
  alt: string;
}

interface HeaderInterface {
  activateLogo: boolean;
  menuItems: MenuItem[];
  brandName: string;
  brandUrl: string;
  brandLogo?: logoImage;
  cta?: CtaButtonInterface;
}

const MenuIcon = (
  <svg
    width="40"
    height="20"
    viewBox="0 0 84 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 60H84V48H0V60ZM0 0V12H84V0H0ZM0 36H84V24H0V36Z"
      fill="#160055"
    />
  </svg>
);

const CloseIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70 7.05L62.95 0L35 27.95L7.05 0L0 7.05L27.95 35L0 62.95L7.05 70L35 42.05L62.95 70L70 62.95L42.05 35L70 7.05Z"
      fill="#160055"
    />
  </svg>
);

const Header = ({ activateLogo, menuItems, brandName, brandUrl, brandLogo, cta }: HeaderInterface) => {
  const menuBtnRef = React.createRef<HTMLButtonElement>();
  const menuItemsList = React.createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const MenuBtnHandler = () => {
    if (
      menuBtnRef &&
      menuBtnRef?.current &&
      menuItemsList &&
      menuItemsList?.current
    ) {
      if (menuItemsList?.current?.classList?.contains("show")) {
        menuBtnRef?.current?.classList?.remove("active");
        menuItemsList?.current.classList?.remove("show");
        setIsOpen((isOpen) => !isOpen);
        return;
      }
      menuBtnRef?.current?.classList?.add("active");
      menuItemsList?.current.classList?.add("show");
      setIsOpen((isOpen) => !isOpen);
    }
  };

  const LinkHandler = () => {
    setIsOpen((isOpen) => !isOpen);
    if (menuItemsList?.current?.classList?.contains("show")) {
      menuBtnRef?.current?.classList?.remove("active");
      menuItemsList?.current.classList?.remove("show");
    }
  };

  return (
    <StyledComponent className="header">
      <div className="header__content">
        <div className="header__top">
          <div className="header__brand">
            {activateLogo && brandLogo ? (
              <div className="header__brand--logo">
                <a href={brandUrl}><img src={brandLogo?.url} alt={brandLogo?.alt} /></a>
              </div>
            ) : (
              <div className="header__brand--name"><a href={brandUrl}>{brandName}</a></div>
            )}
          </div>
          <button
            ref={menuBtnRef}
            onClick={MenuBtnHandler}
            className="header__menu-btn"
          >
            <span className="btn text">{isOpen ? "CLOSE" : "MENU"}</span>
            <span className="btn icon">{isOpen ? CloseIcon : MenuIcon}</span>
          </button>
        </div>
        {menuItems && (
          <div ref={menuItemsList} className="header__menu-items">
            <ul className="header__menu-items--list">
              {menuItems.map((menuItem: MenuItem, index: number) => {
                return (
                  <li
                    key={"menu-item" + menuItem.title + index}
                    onClick={LinkHandler}
                  >
                    <a href={menuItem.link}>{menuItem.title}</a>
                  </li>
                );
              })}
            </ul>
            {cta && (
              <CtaButton
                icon={cta.icon}
                title={cta?.title}
                onClickAction={cta?.onClickAction}
              />
            )}
          </div>
        )}
      </div>
    </StyledComponent>
  );
};

const defaultStyles = () => css`
  &.header {
    display: flex;
    flex-direction: column;
    top: 0;
    position: fixed;
    z-index: 1000;
    background: white;
    width: 100%;
    box-shadow: 2.6px 0px 10px rgba(0, 0, 0, 0.035),
      21px 0px 80px rgba(0, 0, 0, 0.07);
    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      height: 80px;
      margin: 0px auto;
      right: 0;
      left: 0;
    }
  }

  .header__content {
    @media (min-width: 768px) {
      display: flex;
      width: 100%;
      max-width: 1920px;
      margin: 0px auto;
    }
  }

  .header__top {
    display: flex;
    flex-direction: row;
    height: 50px;
  }

  .header__brand {
    width: 100%;
  }

  .header__brand--logo {
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 50px;
    @media (min-width: 768px) {
      margin-left: 60px;
    }
    img {
      width: 120px;
      height: 40px;
    }
  }

  .header__brand--name {
    font-family: "InterBold";
    margin: 16px 0 0 15px;
    @media (min-width: 768px) {
      margin-right: 50px;
      margin-left: 50px;
    }
    a, a:visited, a:hover{
      text-decoration: none;
      color: black;
    }
  }

  .header__menu-btn {
    height: 35px;
    margin: 8px 10px 0 0;
    display: flex;
    flex-direction: row;
    padding: 0 10px 0 10px;
    color: #160055;
    background: none;
    border: none;
    border-radius: 2px;
    font-family: "InterBold";
    @media (min-width: 768px) {
      display: none;
    }
  }

  .btn.text,
  .btn.icon {
    align-self: center;
  }

  .btn.icon {
    margin-left: 5px;
  }

  .header__menu-items {
    display: none;
    @media (min-width: 768px) {
      width: 100%;
      display: flex;
      height: 50px;
    }
  }

  .header__menu-items--list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0px;
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 40px;
    }
    li {
      margin-bottom: 20px;
    }
    li a {
      text-decoration: none;
      font-family: "InterRegular";
      font-size: 16px;
      color: black;
      @media (min-width: 768px) {
        :hover {
          border-bottom: 2px solid #160055;
        }
      }
    }
  }

  .show {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .active {
    background-color: #1600550f;
  }

  .cta-button-wrapper {
    text-align: center;
    padding-bottom: 20px;
    @media (min-width: 768px) {
      margin-left: auto;
      margin-right: 20px;
      :hover {
        opacity: .8;
      }
    }
  }

  .cta-button-wrapper .cta-btn {
    height: 50px;
    border: none;
    font-family: "InterBold";
    background: #160055;
    color: white;
    padding: 0px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
      cursor: pointer;
    }
  }
`;

const StyledComponent = styled.nav`
  ${defaultStyles}
`;

export default Header;

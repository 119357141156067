import styled, { css } from "styled-components";

export interface CtaButtonInterface {
  title: string;
  onClickAction: () => void;
  className?: string;
  icon?: any;
}

const CtaButton = ({
  title,
  onClickAction,
  className,
  icon,
}: CtaButtonInterface) => {
  return (
    <StyledComponent className={className || "cta-button-wrapper"}>
      <button
        aria-label={title}
        className="cta-btn"
        onClick={() => onClickAction()}
      >
        {icon && (
          <span className="cta-icon">{icon}</span>
        )}
        <span className="cta-title">{title}</span>
      </button>
    </StyledComponent>
  );
};

const defaultStyles = () => css`
  .cta-btn {
    width: 100%;
    max-width: 200px;
    height: 54px;
    color: black;
    background: white;
  }
`;

const StyledComponent = styled.div<{
  className: string;
}>`
  ${defaultStyles}
`;

export default CtaButton;

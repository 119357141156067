import React from "react";
import styled, { css } from "styled-components";

// Utils
import { useWindowScrollPositions } from "../../utils/useWindowScrollPosition";
import { useWindowSize } from "../../utils/useWindowSize";

interface VideoPlayer {
  disableRemotePlayback?: boolean;
  loop?: boolean;
  preload?: string;
  muted?: boolean;
  playInline?: boolean;
  url: string;
}

interface StoryCopy extends VideoPlayer {
  subtitle?: string;
  title: string;
  contentBody: string;
}

const StoryBlock = ({
  subtitle,
  title,
  contentBody,
  disableRemotePlayback = true,
  loop = true,
  preload,
  muted = true,
  playInline = true,
  url,
}: StoryCopy) => {
  const { scrollY } = useWindowScrollPositions();
  const screenWidth = useWindowSize();
  const fullScreenRef = React.useRef<HTMLDivElement>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  let fullScreenElement = fullScreenRef.current;

  if (screenWidth.width < 768) {
    if (
      scrollY >= 2250 &&
      scrollY <= 2800 &&
      fullScreenElement &&
      videoRef.current
    ) {
      fullScreenElement.className = "about-us__video-block active";
      videoRef.current.playbackRate = 3.0;
      videoRef.current?.play();
    }

    if (
      (scrollY > 2800 || scrollY < 2250) &&
      fullScreenElement &&
      videoRef.current
    ) {
      fullScreenElement.className = "about-us__video-block";
      videoRef.current?.pause();
    }
  } else {
    if (
      fullScreenElement &&
      videoRef.current
    ) {
      fullScreenElement.className = "about-us__video-block desktop";
      videoRef.current.playbackRate = 3.0;
      videoRef.current?.play();
    }
  }

  return (
    <StyledComponent>
      <div className="about-us__video-block" ref={fullScreenRef}>
        <div className="about-us__copy">
          <p role="doc-subtitle" className="about-us__copy-subheading">
            {subtitle}
          </p>
          <h2 className="about-us__copy-heading">{title}</h2>
          <div className="about-us__copy-body">
          <div dangerouslySetInnerHTML={{__html: contentBody}} />
          </div>
        </div>
        <div className="about-us__video-block__overlay"></div>
        <video
          disableRemotePlayback={disableRemotePlayback}
          loop={loop}
          ref={videoRef}
          preload={preload}
          muted={muted}
          playsInline={playInline}
          src={url}
          tabIndex={0}
        ></video>
      </div>
    </StyledComponent>
  );
};

const defaultStyles = ({ backgroundImage }: any) => css`
  .about-us__video-block {
    width: 370px;
    height: 300px;
    overflow: hidden;
    background-color: #0a0a0a;
    transition: 0.3s ease-in;
    position: relative;
    margin: 0 auto;
    height: 70vh;
    @media (min-width: 768px) {
      width: 95%;
    }
  }

  .about-us__video-block__overlay {
    width: 100%;
    height: 115vh;
    opacity: 0;
    background: rgb(0, 3, 28, 0.8);
    position: absolute;
    transition: 0.5s ease-out;
    @media (min-width: 1366px) {
      height: 70vh;
    }
  }

  .about-us__video-block.active {
    background-color: black;
    width: 100%;
    height: 115vh;
    transition: 0.5s ease-in;
    animation: fullscreen 2s steps(100) forwards;
    .about-us__video-block__overlay {
      opacity: 1;
      transition: 0.5s ease-in;
    }
    @media (min-width: 769px) {
      height: 70vh;
    }
  }

  .desktop {
    @media (min-width: 768px) {
      height: 100%;
      max-height: 500px;
    }
    .about-us__video-block__overlay {
      opacity: 1;
      transition: 0.5s ease-in;
    }
  }

  @keyframes fullscreen {
    0% {
      background-color: #0a0a0a;
    }
    50% {
      transform: scale(1);
    }
    100% {
      background-color: #0a0a0a;
    }
  }

  .about-us__copy {
    position: absolute;
    max-width: 300px;
    margin: 50px 0 0 35px;
    z-index: 1;
    @media (min-width: 768px) {
      max-width: 700px;
      margin: 70px 0 0 60px;
    }
  }

  .about-us__copy-heading {
    line-height: 1;
    font-family: "InterMedium";
    font-size: 24px;
    color: white;
    margin-bottom: 45px;
    @media (min-width: 768px) {
      font-size: 50px;
      line-height: 0;
      margin-bottom: 70px;
    }
  }

  .about-us__copy-subheading {
    font-family: "BaiJamjureeMedium";
    font-size: 14px;
    color: #00f252;
    line-height: 1;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .about-us__copy-body {
    font-family: "InterRegular";
    color: white;
  }
`;

const StyledComponent = styled.div`
  ${defaultStyles}
`;

export default StoryBlock;

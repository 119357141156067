import React from "react";
import styled, { css } from "styled-components";

export enum StatusType {
  Info = 1,
  Success = 2,
  Error = 3,
}

type status = StatusType.Info | StatusType.Success | StatusType.Error;

interface NotificationProps {
  message: string;
  deliveryStatus: status;
}

const InfoIcon = (
  <svg
    width="50"
    height="50"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60 7.5C31.0078 7.5 7.5 31.0078 7.5 60C7.5 88.9922 31.0078 112.5 60 112.5C88.9922 112.5 112.5 88.9922 112.5 60C112.5 31.0078 88.9922 7.5 60 7.5ZM60 103.594C35.9297 103.594 16.4062 84.0703 16.4062 60C16.4062 35.9297 35.9297 16.4062 60 16.4062C84.0703 16.4062 103.594 35.9297 103.594 60C103.594 84.0703 84.0703 103.594 60 103.594Z"
      fill="white"
    />
    <path
      d="M54.375 39.375C54.375 40.8668 54.9676 42.2976 56.0225 43.3525C57.0774 44.4074 58.5082 45 60 45C61.4918 45 62.9226 44.4074 63.9775 43.3525C65.0324 42.2976 65.625 40.8668 65.625 39.375C65.625 37.8832 65.0324 36.4524 63.9775 35.3975C62.9226 34.3426 61.4918 33.75 60 33.75C58.5082 33.75 57.0774 34.3426 56.0225 35.3975C54.9676 36.4524 54.375 37.8832 54.375 39.375V39.375ZM62.8125 52.5H57.1875C56.6719 52.5 56.25 52.9219 56.25 53.4375V85.3125C56.25 85.8281 56.6719 86.25 57.1875 86.25H62.8125C63.3281 86.25 63.75 85.8281 63.75 85.3125V53.4375C63.75 52.9219 63.3281 52.5 62.8125 52.5Z"
      fill="white"
    />
  </svg>
);

const SuccessIcon = (
  <svg
    width="50"
    height="50"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M81.9141 41.3672H76.418C75.2227 41.3672 74.086 41.9414 73.3828 42.9258L54.961 68.4727L46.6172 56.8945C45.9141 55.9219 44.7891 55.3359 43.582 55.3359H38.086C37.3242 55.3359 36.8789 56.2031 37.3242 56.8242L51.9258 77.0742C52.2707 77.5557 52.7255 77.948 53.2523 78.2186C53.7791 78.4892 54.3628 78.6304 54.9551 78.6304C55.5474 78.6304 56.1311 78.4892 56.6579 78.2186C57.1847 77.948 57.6395 77.5557 57.9844 77.0742L82.6641 42.8555C83.1211 42.2344 82.6758 41.3672 81.9141 41.3672V41.3672Z"
      fill="white"
    />
    <path
      d="M60 7.5C31.0078 7.5 7.5 31.0078 7.5 60C7.5 88.9922 31.0078 112.5 60 112.5C88.9922 112.5 112.5 88.9922 112.5 60C112.5 31.0078 88.9922 7.5 60 7.5ZM60 103.594C35.9297 103.594 16.4062 84.0703 16.4062 60C16.4062 35.9297 35.9297 16.4062 60 16.4062C84.0703 16.4062 103.594 35.9297 103.594 60C103.594 84.0703 84.0703 103.594 60 103.594Z"
      fill="white"
    />
  </svg>
);

const ErrorIcon = (
  <svg
    width="50"
    height="50"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80.3203 41.5781C80.3203 41.0625 79.8984 40.6406 79.3828 40.6406L71.6484 40.6758L60 54.5625L48.3633 40.6875L40.6172 40.6523C40.1016 40.6523 39.6797 41.0625 39.6797 41.5898C39.6797 41.8125 39.7617 42.0234 39.9023 42.1992L55.1484 60.3633L39.9023 78.5156C39.7607 78.6874 39.6822 78.9024 39.6797 79.125C39.6797 79.6406 40.1016 80.0625 40.6172 80.0625L48.3633 80.0273L60 66.1406L71.6367 80.0156L79.3711 80.0508C79.8867 80.0508 80.3086 79.6406 80.3086 79.1133C80.3086 78.8906 80.2266 78.6797 80.0859 78.5039L64.8633 60.3516L80.1094 42.1875C80.25 42.0234 80.3203 41.8008 80.3203 41.5781Z"
      fill="white"
    />
    <path
      d="M60 7.61719C31.0078 7.61719 7.5 31.125 7.5 60.1172C7.5 89.1094 31.0078 112.617 60 112.617C88.9922 112.617 112.5 89.1094 112.5 60.1172C112.5 31.125 88.9922 7.61719 60 7.61719ZM60 103.711C35.9297 103.711 16.4062 84.1875 16.4062 60.1172C16.4062 36.0469 35.9297 16.5234 60 16.5234C84.0703 16.5234 103.594 36.0469 103.594 60.1172C103.594 84.1875 84.0703 103.711 60 103.711Z"
      fill="white"
    />
  </svg>
);

const RenderNotification = (message: string, deliveryStatus: status) => {
  switch (deliveryStatus) {
    case StatusType.Info:
      return (
        <div className="notification__body--info">
          <div className="notification__loading-bar"></div>
          <div className="notification__icon">{InfoIcon}</div>
          <div className="notification__message">{message}</div>
        </div>
      );
    case StatusType.Success:
      return (
        <div className="notification__body--success">
          <div className="notification__icon">{SuccessIcon}</div>
          <div className="notification__message">{message}</div>
          <button
            aria-label="Send another message"
            onClick={() => window.location.reload()}
            className="notification__option"
          >
            Send another one
          </button>
        </div>
      );
    default:
      return (
        <div className="notification__body--error">
          <div className="notification__icon">{ErrorIcon}</div>
          <div className="notification__message">{message}</div>
          <button
            aria-label="Click to retry"
            onClick={() => window.location.reload()}
            className="notification__option"
          >
            Click to retry
          </button>
        </div>
      );
  }
};

const Notification = ({ message, deliveryStatus }: NotificationProps) => {
  return (
    <StyledComponent className="notification">
      {RenderNotification(message, deliveryStatus)}
    </StyledComponent>
  );
};

const defaultStyles = () => css`
  @keyframes loading {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .notification__body--info {
    display: flex;
    height: 100px;
    font-size: 20px;
    font-family: "InterMedium";
    flex-direction: row;
    background: #0aa4ff;
    color: white;
    padding: 10px;
    justify-content: center;
    align-items: center;
    .notification__icon {
      padding-right: 10px;
      padding-top: 5px;
      animation: loading 1s infinite;
    }
  }

  .notification__body--success {
    display: flex;
    height: 100px;
    font-size: 20px;
    font-family: "InterMedium";
    flex-direction: row;
    background: #00bf5e;
    color: white;
    padding: 10px;
    justify-content: center;
    align-items: start;
    .notification__icon {
      padding-right: 10px;
      padding-top: 3px;
    }
    .notification__message {
      padding-top: 15px;
    }
    .notification__option {
      color: white;
      font-family: "InterMedium";
      outline: none;
      border: none;
      cursor: pointer;
      position: absolute;
      bottom: 25px;
      font-size: 14px;
      background: #ffffff38;
      padding: 8px 10px 8px 10px;
      border-radius: 4px;
    }
  }

  .notification__body--error {
    display: flex;
    height: 125px;
    font-size: 20px;
    font-family: "InterMedium";
    flex-direction: row;
    background: #dd0f00;
    color: white;
    padding: 10px;
    justify-content: center;
    align-items: start;
    .notification__message {
      padding: 0;
      @media (min-width: 768px) {
        padding-top: 24px;
      }
    }
    .notification__icon {
      bottom: 15px;
      padding: 10px;
    }
    .notification__option {
      bottom: 15px;
      padding: 10px;
      @media (min-width: 768px) {
        right: unset;
      }
    }
  }

  .notification__option {
    color: white;
    font-family: "InterMedium";
    outline: none;
    border: none;
    cursor: pointer;
    position: absolute;
    font-size: 14px;
    background: #ffffff38;
    border-radius: 4px;
  }

  .notification__loading-bar {
    background: repeating-linear-gradient(
      to right,
      white 0%,
      #0aa4ff 50%,
      #160055 100%
    );
    width: 100%;
    height: 5px;
    background-size: 200% auto;
    background-position: 0 100%;
    -webkit-animation: gradient 1s infinite;
    animation: gradient 1s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    position: absolute;
    top: 0;
  }

  @keyframes gradient {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

const StyledComponent = styled.div<{
  className?: string;
}>`
  ${defaultStyles}
`;

export default Notification;

import styled, { css } from "styled-components";

interface Company {
  title: string;
  logoUrl: string;
  siteUrl?: string;
}

interface ShowcaseInterface {
  companies: Company[];
  title: string;
  subtitle?: string;
}

const Showcase = ({ companies, title, subtitle }: ShowcaseInterface) => {
  return (
    <StyledComponent className="showcase">
      <div className="showcase__copy">
        <h2 className="showcase__title">{title}</h2>
        <div className="showcase__subtitle">{subtitle}</div>
      </div>
      <div className="showcase__grid">
          {companies?.map((company: Company, index: number) => {
            return (
              <div className="showcase__grid-item" key={"showcase-item-" + index + company.title}>
                <p className="showcase__grid-item-name">{company?.title}</p>
                <img className="showcase__grid-item-logo" src={company?.logoUrl} alt={company?.title}/>
              </div>
            );
          })}
        </div>
    </StyledComponent>
  );
};

const defaultStyles = () => css`
  .showcase__grid {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 768px) {
      padding: 0 35px;
    }
  }
  .showcase__grid-item {
    display: flex;
    flex-direction: column;
    max-width: 370px;
    margin: 20px auto;
  }
  .showcase__copy {
    font-family: "InterBold";
    margin: 0px auto 30px auto;
    text-align: center;
  }
  .showcase__title {
    font-size: 35px;
    font-family: "InterBold";
    line-height: 0;
  }
  .showcase__subtitle {
    font-size: 20px;
    font-family: "InterMedium";
    line-height: 1.3;
    color: #160055;
    padding: 15px;
  }
  .showcase__grid-item-name {
    font-family: "InterBold";
    text-align: center;
    max-width: 200px;
    margin: 0 auto 25px auto;
  }
  .showcase__grid-item-logo {
    margin: 0px auto;
    max-width: 250px;
    /* filter: grayscale(1); */
    :hover {
      cursor: pointer;
      /* filter: grayscale(0); */
    }
  }
`;

const StyledComponent = styled.div<{
  className: string;
}>`
  ${defaultStyles}
`;

export default Showcase;

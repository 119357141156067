import React from "react";
import styled, { css } from "styled-components";

import { useForm } from "react-hook-form";

interface FormValues {
  name: string;
  emailAddress: string;
  company?: string;
  subject?: string;
  message?: string;
}

const ContactForm = ({ onSubmit }: any) => {
  const [disable, setDisable] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<number>(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      emailAddress: "",
      company: "",
      subject: "",
      message: "",
    },
  });

  const DisableForm = () => {
    setStep(step + 1);
    if (Object.keys(errors).length <= 0 && step > 0) {
      setTimeout(() => {
        setDisable(true);
      }, 1000);
    }
  };

  return (
    <StyledComponent>
      <form className="contact-us__form" onSubmit={handleSubmit(onSubmit)}>
        <p className="info">Fields marked with <span className="required">*</span> are required</p>
        <label htmlFor="name">
          Full Name<span>*</span>
        </label>
        {errors.name && (
          <p className="error">Required: Please enter your name</p>
        )}
        <input
          id="name"
          className="contact-us__form-field"
          type="text"
          placeholder="Elliot Alderson"
          {...register("name", { maxLength: 100, required: true })}
        />
        <label htmlFor="emailAddress">
          Email<span>*</span>
        </label>
        {errors.emailAddress && (
          <p className="error">Required: Please enter a valid email address</p>
        )}
        <input
          id="emailAddress"
          className="contact-us__form-field"
          type="text"
          placeholder="elliot.alderson@e-corp-usa.com"
          {...register("emailAddress", {
            required: true,
            pattern: /^\S+@\S+$/i,
          })}
        />
        <label htmlFor="company">Company Name</label>
        <input
          id="company"
          className="contact-us__form-field"
          type="text"
          placeholder="Your Company Name"
          {...register("company", { maxLength: 100 })}
        />
        <label htmlFor="contact-form-subject">
          Subject<span>*</span>
        </label>
        {errors.subject && (
          <p className="error">Required: Please enter a subject</p>
        )}
        <input
          id="contact-form-subject"
          className="contact-us__form-field"
          type="text"
          placeholder="Your message subject"
          {...register("subject", { required: true })}
        />
        <label htmlFor="message">
          Your Message<span>*</span>
        </label>
        {errors.message && (
          <p className="error">Required: Please enter your message</p>
        )}
        <textarea
          id="message"
          className="contact-us__form-field--message"
          placeholder="Hello..."
          {...register("message", { maxLength: 200, required: true })}
        />
        {!disable && (
          <button
            className="contact-us__form-button"
            type="submit"
            onClick={() => DisableForm()}
          >
            SEND MESSAGE
          </button>
        )}
      </form>
    </StyledComponent>
  );
};

const defaultStyles = () => css`
  .contact-us__form-field {
    font-family: "BaiJamjureeRegular";
    height: 40px;
    width: 100%;
    background: #ffffff;
    border: 2px solid #d6d6d6;
    box-sizing: border-box;
  }

  .contact-us__form-field--message {
    font-family: "BaiJamjureeRegular";
    height: 40px;
    width: 100%;
    background: #ffffff;
    border: 2px solid #d6d6d6;
    box-sizing: border-box;
  }
  .contact-us__form-button {
    width: 100%;
    font-family: "BaiJamjureeRegular";
    color: white;
    border: none;
    padding: 20px;
    text-align: center;
    background: #160055;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }
  .error {
    background: #ff1414;
    padding: 4px;
    color: white;
    margin: 0px;
  }
  label {
    color: #160055;
    font-family: "InterBold";
    display: block;
    margin-bottom: 5px;
    span {
      color: #A80000;
    }
  }

  .info {
    font-size: 14px;
    font-family: "InterRegular";
    text-align: center;
  }
  .required {
    color: #A80000;
  }
`;

const StyledComponent = styled.div`
  ${defaultStyles}
`;

export default ContactForm;

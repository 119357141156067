import styled, { css } from "styled-components";

interface ServiceInterface {
  title: string;
  description: string;
  offering?: string;
  icon?: any;
  background?: string;
}

const ServiceCard = ({
  title,
  description,
  offering,
  icon,
  background,
}: ServiceInterface) => {
  return (
    <StyledComponent backgroundImage={background} className="service">
      <div className="service-card">
        <div className="service-offering" aria-hidden="true">
          {offering}
        </div>
        <i className="service-card__icon" aria-hidden="true">
          {icon}
        </i>
        <div className="service-card__copy">
          <div className="service-card__title">
            <h2>{title}</h2>
          </div>
          <div className="service-card__description">{description}</div>
        </div>
      </div>
    </StyledComponent>
  );
};

const defaultStyles = ({ backgroundImage }: any) => css`
  .service-card {
    position: relative;
    max-width: 370px;
    min-height: 330px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    border-radius: 4px;
    background: ${backgroundImage ? `url(${backgroundImage})` : "#f4f4f4"};
    background-repeat: no-repeat;
    background-size: 445px auto;
    border: 4px solid white;
    .service-card__icon {
      opacity: 1;
      transition: 0.2s ease-out;
    }
    @media (min-width: 768px) {
      transition: 0.2s ease-out;
      :hover {
        border: 4px solid #c7aca3;
        cursor: pointer;
        transition: 0.2s ease-in;
        box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
          0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
          0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
        .service-card__icon {
          opacity: 0;
          transition: 0.2s ease-out;
        }

        .service-offering {
          display: block;
        }
      }
    }
  }

  .service-offering {
    display: none;
    position: absolute;
    max-width: 300px;
    padding: 30px;
    color: #ffffff;
    background: linear-gradient(to right, #160055 0, #7583ff 10%, #160055 20%);
    background-position: -40px;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 2s linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    font-size: 25px;
    font-family: 'InterBold';
    text-decoration: none;
  }

  @keyframes shine {
    0% {
      background-position: -40px;
    }

    60% {
      background-position: 180px;
    }
    100% {
      background-position: 300px;
    }
  }

  .service-card__copy {
    width: 100%;
    height: inherit;
  }

  .service-card__title {
    font-family: "InterBold";
    font-size: 16px;
    padding: 0 0 0 35px;
    line-height: 1.6;
  }

  .service-card__description {
    font-family: "InterRegular";
    font-size: 17px;
    padding: 0 35px 35px 35px;
    max-width: 300px;
    position: absolute;
    bottom: 0;
    background: linear-gradient( 180deg,rgba(255,255,255,0) 0%,rgb(207 210 223) 100% );
  }

  .service-card__icon {
    padding: 35px 0 0 35px;
  }
`;

const StyledComponent = styled.div<{
  backgroundImage?: string;
  className: string;
}>`
  ${defaultStyles}
`;

export default ServiceCard;

/* eslint-disable jsx-a11y/img-redundant-alt */
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import CtaButton from "../cta-button/cta-button";

// Import Swiper styles
import "swiper/css";

interface CallToAction {
  title: string;
  callback?: any;
}

interface ImageProperties {
  src: string;
  srcSet?: string;
  alt?: string;
  type?: string;
}

interface Image {
  mobile: ImageProperties;
  desktop: ImageProperties;
}

interface Slide {
  image: Image;
  subtitle?: string;
  description?: string;
  category?: string;
  cta?: CallToAction;
}

interface SliderProps {
  slides: Slide[];
}

const RenderSlide = (collection: any) =>
  collection.slides.map((slide: Slide, index: number) => {
    return (
      <SwiperSlide
        key={`slider-${slide?.image?.mobile?.alt}-${index}`}
        className="homepage-slider__slide"
      >
        <div className="homepage-slider__slide--overlay">
          <div className="homepage-slider__slide-copy">
            {slide?.category && (
              <div className="service-category" aria-hidden="true">
                {slide?.category}
              </div>
            )}
            {slide?.subtitle && <h2>{slide?.subtitle}</h2>}
            {slide?.description && <p>{slide?.description}</p>}
            {slide?.cta?.title && slide?.cta?.callback && (
              <CtaButton
                title={slide?.cta?.title}
                onClickAction={slide?.cta?.callback}
              />
            )}
          </div>
        </div>
        <picture>
          <source
            srcSet={slide?.image?.desktop?.srcSet}
            media="(min-width: 768px)"
          />
          <source
            srcSet={slide?.image?.mobile?.srcSet}
            media="(min-width: 200px)"
          />
          <img
            src={slide?.image?.desktop?.src}
            alt={slide?.image?.desktop?.alt}
          />
        </picture>
      </SwiperSlide>
    );
  });

const Carousel = (slides: SliderProps) => {
  return (
    <Swiper modules={[Autoplay]} className="homepage-slider" autoplay>
      {RenderSlide(slides)}
    </Swiper>
  );
};

export default Carousel;

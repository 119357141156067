import React from "react";
import styled, { css } from "styled-components";

import ContactForm from "../contact-form/ContactForm";

import backgroundImage from "./assets/background.png";

interface ContactMethodsProps {
  telephoneTitle?: string;
  telephoneNumber?: string;
  emailTitle?: string;
  emailAddress?: string;
}
interface ContactProps {
  title: string;
  subtitle?: string;
  contact: ContactMethodsProps;
  register?: any;
  handleSubmit?: any;
  onSubmit?: any;
}

const ContactUs = ({
  title,
  subtitle,
  contact,
  register,
  handleSubmit,
  onSubmit,
}: ContactProps) => {
  return (
    <StyledComponent className="contact-us">
      <div className="contact-us__left">
        <div className="contact-us__copy">
          <h2 className="contact-us__title">{title}</h2>
          <h3 className="contact-us__subtitle">{subtitle}</h3>
          <div className="contact-us__detail">
            {contact?.telephoneNumber && (
              <React.Fragment>
                <p className="contact-us__detail--title">
                  {contact?.telephoneTitle}
                </p>
                <a
                  className="contact-us__detail--number"
                  href={`tel:${contact?.telephoneNumber}`}
                >
                  {contact?.telephoneNumber}
                </a>
              </React.Fragment>
            )}
            <p className="contact-us__detail--title">{contact?.emailTitle}</p>
            <a
              className="contact-us__detail--email"
              href={`mailto:${contact?.emailAddress}`}
            >
              {contact?.emailAddress}
            </a>
          </div>
        </div>
      </div>
      <hr className="hr-text" data-content="OR" />
      <div className="contact-us__right">
        <ContactForm
          register={register}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
        />
      </div>
    </StyledComponent>
  );
};

const defaultStyles = () => css`
  &.contact-us {
    display: flex;
    flex-direction: column;
    border-top: 6px solid #160055;
    border-bottom: 6px solid #160055;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  .contact-us__left,
  .contact-us__right {
    width: 100%;
    margin: 0px auto;
    @media (min-width: 768px) {
      padding: 50px 0 50px 0;
    }
  }

  .contact-us__right {
    padding-bottom: 50px;
    @media (min-width: 768px) {
      background: url(${backgroundImage});
    }
  }

  .contact-us__copy {
    max-width: 350px;
    padding: 100px 0 0 0;
    margin: 0px auto;
  }
  .contact-us__title {
    font-size: 36px;
    line-height: 0;
  }

  .contact-us__subtitle {
    font-size: 22px;
    color: #160055;
    font-family: "InterRegular";
    line-height: 1.5;
  }

  .contact-us__detail {
    margin-bottom: 25px;
  }

  p.contact-us__detail--title {
    font-size: 14px;
    font-family: "InterRegular";
    width: fit-content;
    border-bottom: 2px solid #00f252;
  }

  .contact-us__detail--number,
  .contact-us__detail--email,
  .contact-us__detail--number:visited,
  .contact-us__detail--email:visited {
    color: black;
    font-family: "BaiJamjureeRegular";
    font-size: 18px;
    margin-bottom: 25px;
    text-decoration: none;
  }

  .contact-us__form {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }

  .contact-us__form-field,
  .contact-us__form-field--message {
    margin: 0 auto 20px auto;
    padding: 10px;
  }

  .contact-us__form-field--message {
    max-width: 350px;
    min-width: 350px;
    min-height: 200px;
  }

  .hr-text {
    margin: 0 40px 20px 40px;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 25px;
    @media (min-width: 768px) {
      display: none;
    }
    &:before {
      content: "";
      background: black;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      font-family: "InterBold";
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: black;
      padding: 0px 20px 0 20px;
      line-height: 25px;
      background-color: white;
    }
  }
`;

const StyledComponent = styled.div<{
  className?: string;
}>`
  ${defaultStyles}
`;

export default ContactUs;

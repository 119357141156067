import styled, { css } from "styled-components";
import CtaButton from "../cta-button/cta-button";

interface ServiceInterface {
  title: string;
  description: string;
  category?: string;
  background?: string;
  overlayColor?: string;
  ctaCallback?: () => void;
}

const ServiceBlock = ({
  title,
  description,
  category,
  background,
  overlayColor,
  ctaCallback,
}: ServiceInterface) => {
  return (
    <StyledComponent
      backgroundImage={background}
      overlayColor={overlayColor}
      className="service-block"
    >
      <div className="service-block">
        <div className="service-block__copy">
          <div className="service-category" aria-hidden="true">
            {category}
          </div>
          <div className="service-block__title">
            <h2>{title}</h2>
          </div>
          <div className="service-block__description">{description}</div>
          {ctaCallback && (
            <CtaButton
              className="service-block-cta"
              onClickAction={() => ctaCallback()}
              title="Request a quote"
            />
          )}
        </div>
      </div>
    </StyledComponent>
  );
};

const defaultStyles = ({ backgroundImage, overlayColor }: any) => css`
  .service-block {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    background: ${backgroundImage ? `url(${backgroundImage})` : "#f4f4f4"};
    background-repeat: no-repeat;
    background-size: 630px auto;
    @media (min-width: 768px) {
      max-width: 600px;
      height: 370px;
      background-size: 600px auto;
      :hover {
        .service-block__copy {
          background: linear-gradient(
            99.65deg,
            rgb(29 0 91 / 70%) 0%,
            rgb(29 0 91 / 70%) 0.01%,
            rgb(27 0 61 / 70%) 100%
          );
        }
      }
    }
  }

  .service-category {
    line-height: 0;
    padding: 30px 0 0 35px;
    color: #00f252;
    font-size: 12px;
    font-family: "BaiJamjureeMedium";
  }

  .service-block__copy {
    color: white;
    width: 100%;
    height: inherit;
    background: ${overlayColor ? overlayColor : "#0a0a0a"};
  }

  .service-block__title {
    line-height: 1.1;
    margin-top: -8px;
    margin-bottom: 30px;
    font-family: "InterBold";
    font-size: 16px;
    line-height: 1.5;
    padding: 0 0 0 35px;
  }

  .service-block__description {
    font-family: "InterRegular";
    font-size: 16px;
    padding: 0 35px 35px 35px;
  }

  .service-block-cta {
    padding: 0 35px 35px 35px;
    .cta-btn {
      width: 230px;
      height: 55px;
      font-size: 14px;
      font-family: "InterMedium";
      outline: none;
      border: none;
      transition: 0.3s ease-in-out;
      @media (min-width: 768px) {
        position: absolute;
        bottom: 30px;
        :hover {
          cursor: pointer;
          color: white;
          background: black;
          transition: 0.3s ease-in-out;
          box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
            0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
            0 8px 8px hsl(0deg 0% 0% / 0.075),
            0 16px 16px hsl(0deg 0% 0% / 0.075);
        }
      }
    }
  }
`;

const StyledComponent = styled.div<{
  backgroundImage?: string;
  className: string;
  overlayColor?: string;
}>`
  ${defaultStyles}
`;

export default ServiceBlock;
